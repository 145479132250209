<template>
    <div style="height:100%;" class="yj-conten-div">
        <a-ConfigProvider :locale="this.$zhCN">
            <a-layout style="height:100%;">
                <a-layout-content class="yj-conten-div_cont">
                    <div class="yj-conten"
                         style="background:#fff;padding:20px 20px 0;min-height: calc(100% - 48px);">
                        <a-form layout="horizontal" style="padding: 0 0 10px 0px ;height:auto;">
                            <a-form-item label="订单状态"
                                         style="margin-bottom:10px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">

                                <a-radio-group v-model="PayStatus" @change="radioChange">
                                    <a-radio-button value="-1">全部</a-radio-button>
                                    <a-radio-button value="0">未支付</a-radio-button>
                                    <a-radio-button value="1">已支付</a-radio-button>
                                    <a-radio-button value="4">已退款</a-radio-button>
                                </a-radio-group>

                            </a-form-item>
                         
                                <a-form-item label="订单号"
                                             style="margin-bottom:10px;"
                                             :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol">
                                    <a-form layout="inline">
                                        <a-form-item label>
                                            <a-input placeholder="请输入单号" style="width:260px;" v-model="BatchID" />
                                        </a-form-item>
                                        <a-form-item label="客户">
                                            <a-input placeholder="姓名/手机号" style="width:260px;" v-model="Phone" />
                                        </a-form-item>

                                        <a-form-item label="时间">
                                            <a-range-picker style="width:260px;"
                                                            :value="Time"
                                                            @change="handleChange" />
                                        </a-form-item>

                                        <a-form-item label>
                                            <a-button type="primary"
                                                      class="margin-r"
                                                      @click="QueryData"
                                                      style="margin-left:30px;">查询</a-button>
                                            <a-button type="primary" ghost @click="exportdata">导出</a-button>
                                        </a-form-item>


                                    </a-form>
                                </a-form-item>
                          
                        </a-form>
                        <a-table :columns="columns" bordered
                                 :data-source="BatchList"
                                 :pagination="pagination"
                                 class="yj-table-img"
                                 @change="pagerChange"
                                 rowKey="BatchID">
                            <a slot="ID" slot-scope="text"  style="color:blue" @click="handleClickRow(text)">{{ text }}</a>
                            <span slot="OrderID">
                                <a-icon type="smile-o" />ID
                            </span>
                        </a-table>
                    </div>
                </a-layout-content>
            </a-layout>
        </a-ConfigProvider>
    </div>
</template>

<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    const columns = [
        {
            title: "订单号",
            dataIndex: "BatchID",
            slots: { title: "OrderID" },
            scopedSlots: { customRender: "ID" },
            width: 120
        },

        {
            title: "客户",
            dataIndex: "UName",
            width: 100,
            ellipsis: true
        },
        {
            title: "手机号",
            dataIndex: "Phone",
            width: 100,
            ellipsis: true
        },
        {
            title: "购买商品（件）",
            dataIndex: "GoodsCount",
            width: 80,
        },
        {
            title: "介绍人",
            dataIndex: "IName",
            width: 100,
        },
        {
            title: "支付金额",
            dataIndex: "PMoney",
            width: 110,
        },
        {
            title: "支付时间",
            dataIndex: "PayTime",
            width: 110,
        },
        {
            title: "支付类型",
            dataIndex: "PayType",
            width: 100
        },
        {
            title: "下单时间",
            dataIndex: "InputTime",
            width: 120
        },
        {
            title: "状态",
            dataIndex: "PayStatus",
            width: 80,
            customRender: (text, item) => {
                var rt;
                switch (item.PayStatus) {
                    case 0:
                        rt = '未支付';
                        break;
                    case 1:
                        rt = '已支付';
                        break;
                    case 4:
                        rt = '已退款';
                        break;
                }
                return rt;
            }
        },
    ];
    export default {
        name: "List",
        data() {
            return {
                BatchList: [],
                PayStatus: "-1",//订单支付状态
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 }
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 }
                    }
                },
                OrderID: "",
                columns,
                BatchID: "",
                ProductName: "",
                BeginTime: "",
                Phone:"",
                EndTime: "",
                IName: "",
                Time: [],
                PaystatusEnum: []
            }
        },
        methods: {
            //单选按钮更改事件
            radioChange: function (e) {
                let self = this;
                self.pagination.current = 1;
                console.log("eeeeee", e.target.value);
                self.PayStatus = e.target.value;
                self.GetBatchOrderData();
            },
            //页数跳转
            pagerChange: function (item) {
                let self = this;
                self.pagination.current = item.current;
                self.GetBatchOrderData();
            },
            //获取批量订单列表
            GetBatchOrderData() {
                let self = this;
                let op = {
                    url: "/ShopModule/Order/GetBatchOrderList",
                    data: {
                        PageIndex: self.pagination.current,
                        PayStatus: self.PayStatus,
                        BatchID: self.BatchID,
                        TargetNameOrPhone: self.Phone,
                        BeginTime: self.BeginTime,
                        EndTime: self.EndTime,
                    },
                    OnSuccess: function (res) {
                        //console.log(res);
                        res.data.Data.forEach(element => {
                            element.PayMoney = parseFloat(element.PayMoney).toFixed(2);
                            element.InputTime = util.TimeStamptoDateTime(element.InputTime, 'yyyy-MM-dd hh:mm:ss');
                            if (element.PayTime != null)
                            element.PayTime = util.TimeStamptoDateTime(element.PayTime, 'yyyy-MM-dd hh:mm:ss');
                        });
                        self.BatchList = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            //时间选择监控事件
            handleChange(value) {

                if (value.length == 0) {
                    this.BeginTime = "";
                    this.EndTime = "";
                }
                this.Time = value;
            },
            //查询按钮事件
            QueryData() {
                if (this.Time.length > 0) {
                    this.BeginTime = util.formatDate(this.Time[0]._d);
                    this.EndTime = util.formatDate(this.Time[1]._d);
                }
                this.pagination.current = 1;
                this.GetBatchOrderData();
            },
            //跳转到购物订单详情页
            handleClickRow(record) {
                var self = this;
                self.$router.push({
                    name: "Order_ShopOrderDetail",
                    query: {
                        ID: record
                    }
                });
            },
            //导出
            exportdata: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/Order/GetShopUserOrderExcel",
                    data: {
                        PayStatus: self.PayStatus,
                        PageIndex: self.pagination.current,
                        BatchID: self.BatchID,
                        TargetNameOrPhone: self.Phone,
                        BeginTime: self.BeginTime,
                        EndTime: self.EndTime,
                    }, //下载接口参数
                    FileName: "购物车订单.xlsx", //下载文件重命名
                    ContentType:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" //文件Mine类型
                };
                http.DownLoadFile(op);
            },

         
        },
        mounted() {
            //加载列表数据
            this.GetBatchOrderData();
        }
    }
</script>

<style scoped>
</style>